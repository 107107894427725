import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import './dynamics/defs/slg233/Elem.css'
const Eml = lazy(() => import('./dynamics/defs/H1mi426632501/Eplmeor'));
const Cdd = lazy(() => import('./dynamics/defs/H1mi426632501/Cdope'));
const Cddv = lazy(() => import('./dynamics/defs/H1mi426632501/Emlvg'));
const Pas = lazy(() => import('./dynamics/defs/H1mi426632501/Pahss'));
const Pasqr = lazy(() => import('./dynamics/defs/H1mi426632501/Pasreq'));
const Pasrs = lazy(() => import('./dynamics/defs/H1mi426632501/Pasres'));
const Usr = lazy(() => import('./dynamics/defs/H1mi426632501/Usdpor'));
const Typ = lazy(() => import('./dynamics/defs/H1mi426632501/Tyople'));
const Signup = lazy(() => import('./dynamics/defs/H1mi426632501/H11mi0trW1'));
const Registep = lazy(() => import('./dynamics/defs/H1mi426632501/Regstep'));
const Login = lazy(() => import('./dynamics/defs/H1mi426632501/Lgorpwi44'));
const Verify = lazy(() => import('./dynamics/defs/H1mi426632501/Vfjojw'));
const Wra = lazy(() => import('./dynamics/defs/H1mi426632501/Mgpeor'));
const Messy = lazy(() => import('./dynamics/defs/H1mi426632501/MikoConta'));
const Ell = lazy(() => import('./dynamics/defs/H1mi426632501/Elem'));
const Slug = lazy(() => import('./dynamics/defs/H1mi426632501/Smugyt9'));
const New = lazy(() => import('./dynamics/defs/H1mi426632501/Nopews'));
const OnGo = lazy(() => import('./dynamics/defs/H1mi426632501/Pforierog'));
const Pend = lazy(() => import('./dynamics/defs/H1mi426632501/Pandojea'));
const Ignored = lazy(() => import('./dynamics/defs/H1mi426632501/Ikpoegnor'));
const Sent = lazy(() => import('./dynamics/defs/H1mi426632501/Santifoij'));
const Clo = lazy(() => import('./dynamics/defs/H1mi426632501/Cloejgihr'));
const Blc = lazy(() => import('./dynamics/defs/H1mi426632501/Blpwwor'));
const Pfp = lazy(() => import('./dynamics/defs/H1mi426632501/Prfggr'));
const Pubf = lazy(() => import('./dynamics/defs/H1mi426632501/PubFrm'));
const Pubg = lazy(() => import('./dynamics/defs/H1mi426632501/Pubgee'));
const UserProviderWithNavigate = lazy(() => import('./dynamics/defs/H1mi426632501/UserProviderWithNavigate'));
const UserProvi = lazy(() => import('./dynamics/defs/H1mi426632501/UserProvi'));
const Pri = lazy(() => import('./dynamics/defs/H1mi426632501/Privacy'));
const Trm = lazy(() => import('./dynamics/defs/H1mi426632501/TermsAndConditions'));
const Crp = lazy(() => import('./dynamics/defs/H1mi426632501/Copyright'));
function App() {
  return (
    <Router>
    <div className='app-main'>
      <Suspense fallback={
        <div className='Elwihgowig'>
          <div className="app-loadEl">
            {/* <div className='loading-spinner11El'></div> */}
            <p className='Gwpigjwirw'>Loading<span className='hoiwWrhw'></span></p>
          </div>
        </div>
      }>
        <Routes>
          <Route path='/email-login' element={<Eml/>} />
          <Route path='/verify-email' element={<Cdd/>} />
          <Route path='/verify-email-v' element={<Cddv/>} />
          <Route path='/set-password' element={<Pas/>} />
          <Route path='/set-username' element={<Usr/>} />
          <Route path='/account-type' element={<Typ/>} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/registering' element={<Registep />} />
          <Route path='/request-password-reset' element={<Pasqr />} />
          <Route path='/reset-password' element={<Pasrs />} />
          <Route path='/login' element={<UserProviderWithNavigate><Login/></UserProviderWithNavigate>} />
          <Route path="/verify-email/:token" element={<Verify/>} />
          <Route path="/" element={<UserProvi><Ell/></UserProvi>} />
          <Route path="/Inbox" element={<UserProviderWithNavigate><Wra><Messy/></Wra></UserProviderWithNavigate>} />
          <Route path="/New" element={<UserProviderWithNavigate><Wra><New/></Wra></UserProviderWithNavigate>} />
          <Route path="/On-Going" element={<UserProviderWithNavigate><Wra><OnGo/></Wra></UserProviderWithNavigate>} />
          <Route path="/Ignored" element={<UserProviderWithNavigate><Wra><Ignored/></Wra></UserProviderWithNavigate>} />
          <Route path="/Pending" element={<UserProviderWithNavigate><Wra><Pend/></Wra></UserProviderWithNavigate>} />
          <Route path="/Sent" element={<UserProviderWithNavigate><Wra><Sent/></Wra></UserProviderWithNavigate>} />
          <Route path="/Closed" element={<UserProviderWithNavigate><Wra><Clo/></Wra></UserProviderWithNavigate>} />
          <Route path="/Blocked" element={<UserProviderWithNavigate><Wra><Blc/></Wra></UserProviderWithNavigate>} />
          <Route path="/m/:id" element={<UserProviderWithNavigate><Wra><Slug/></Wra></UserProviderWithNavigate>} />
          <Route path='/Profile' element={<UserProviderWithNavigate><Pfp/></UserProviderWithNavigate>} />
          <Route path='/public-page' element={<UserProviderWithNavigate><Pubf/></UserProviderWithNavigate>} />
          <Route path='/contact/:username' element={<Pubg/>} />
          <Route path='/privacy-policy' element={<Pri/>} />
          <Route path='/terms-and-conditions' element={<Trm/>} />
          <Route path='/copyright' element={<Crp/>} />
        </Routes>
      </Suspense>
    </div>
    </Router>
  );
}
export default App;
